import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import Photo from '@components/photo'
import CustomLink from '@components/link'

export const serializers = {
  types: {
    block: (props) => {
      const { markDefs, style = 'normal' } = props.node

      // check if our block contains a button
      const hasButton =
        markDefs &&
        markDefs.some((c) => c._type === 'link' && c.isButton === true)

      // build our mock header styles
      if (style === 'h1mock') {
        return (
          <p className={cx('is-h1', { 'has-btn': hasButton })}>
            {props.children}
          </p>
        )
      }

      if (style === 'h1mockxl') {
        return (
          <p className={cx('is-h1xl', { 'has-btn': hasButton })}>
            {props.children}
          </p>
        )
      }

      if (style === 'h1xl') {
        return (
          <h1 className={cx('is-h1xl', { 'has-btn': hasButton })}>
            {props.children}
          </h1>
        )
      }

      if (style === 'h2mock') {
        return (
          <p className={cx('is-h2', { 'has-btn': hasButton })}>
            {props.children}
          </p>
        )
      }

      if (style === 'h3mock') {
        return (
          <p className={cx('is-h3', { 'has-btn': hasButton })}>
            {props.children}
          </p>
        )
      }

      if (style === 'h4mock') {
        return (
          <p className={cx('is-h4', { 'has-btn': hasButton })}>
            {props.children}
          </p>
        )
      }

      // go through our remaing, true header styles
      if (/^h\d/.test(style)) {
        return React.createElement(
          style,
          { className: hasButton ? 'has-btn' : null },
          props.children
        )
      }

      // handle all other blocks with the default serializer
      return BlockContent.defaultSerializers.types.block(props)
    },
    figure: ({ node }) => {
      return (
        <div className="rc__image">
          <div
            className={cx({
              'w-full': node.size === 'full',
              'w-3/4': node.size === 'large',
              'w-2/4': node.size === 'medium',
              'w-1/4': node.size === 'small',
            })}
          >
            <Photo photo={node} layout="responsive" />
            {node.content ? (
              <div className="mt-3">
                <BlockContent
                  className="rc"
                  blocks={node.content}
                  serializers={serializers}
                />
              </div>
            ) : null}
          </div>
        </div>
      )
    },
    horizontalRule: () => <hr />,
  },
  marks: {
    link: ({ mark, children }) => {
      return <CustomLink link={{ ...mark, ...{ title: children[0] } }} />
    },
  },
}

import React, { useEffect } from 'react'
import Head from 'next/head'
import { m } from 'framer-motion'
import { imageBuilder } from '@lib/sanity'
import { useRouter } from 'next/router'

import { isBrowser, useWindowSize } from '@lib/helpers'

import generateSchema from '@lib/schema'

import CookieBar from '@modules/shared/cookie-bar'
import Header from '@modules/shared/header'
import Footer from '@modules/shared/footer'
import { GoogleTagManagerScripts } from '@modules/shared/google-tag-manager'
import { FacebookPixelScript } from '@modules/shared/facebook-pixel'
import { SiteSettings } from '@lib/api'
import { pageTransitionSpeed } from '@lib/animate'
import { formatAlternateUrl } from '@components/link'

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionSpeed / 1000,
      delay: 0.3,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: pageTransitionSpeed / 1000,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

const Layout = ({
  site,
  page,
  schema,
  children,
}: {
  site: SiteSettings
  page: any
  schema?: any
  children: any
}) => {
  // set <head> variables
  const siteTitle = site.seo?.siteTitle

  const metaTitle = page.seo?.metaTitle || site.seo?.metaTitle
  const metaDesc = page.seo?.metaDesc || site.seo?.metaDesc
  const noIndex = page.seo?.noIndex
  const shareGraphic =
    page.seo?.shareGraphic?.asset || site.seo?.shareGraphic?.asset

  const updatedAt = page._updatedAt
  const createdAt = page._createdAt

  // set window height var
  const { height: windowHeight } = useWindowSize()

  useEffect(() => {
    if (isBrowser) {
      document.body.style.setProperty('--vh', `${windowHeight * 0.01}px`)
    }
  }, [windowHeight])

  const router = useRouter()

  const canonicalPath =
    router.locale === 'ja' ? router.asPath : `/${router.locale}${router.asPath}`
  const canonicalUrl = `${process.env.CANONICAL_URL}${canonicalPath}`

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href={canonicalUrl} />
        <GoogleTagManagerScripts />
        <FacebookPixelScript />
        <link rel="icon" href="/favicon.ico" />

        <link rel="preconnect" href="https://longforme.myshopify.com" />
        <link rel="preconnect" href="https://shop.jau.co.jp" />
        <link
          rel="preconnect"
          href="https://cdn.sanity.io"
          crossOrigin="true"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <script
          async
          type="text/javascript"
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TewpK8"
        ></script>
        {router.locales.map((locale) => (
          <link
            key={locale}
            rel="alternate"
            href={formatAlternateUrl(locale, router)}
            hrefLang={locale}
          />
        ))}
        <link
          rel="alternate"
          href={formatAlternateUrl(router.defaultLocale, router)}
          hrefLang="x-default"
        />

        <title>{metaTitle}</title>
        {metaDesc && <meta name="description" content={metaDesc} />}

        {metaTitle && (
          <>
            <meta property="og:title" content={metaTitle} />
            <meta name="twitter:title" content={metaTitle} />
          </>
        )}

        {noIndex && <meta name="robots" content="noindex" />}

        {metaDesc && (
          <>
            <meta property="og:description" content={metaDesc} />
            <meta name="twitter:description" content={metaDesc} />
          </>
        )}

        {updatedAt && (
          <meta property="article:modified_time" content={updatedAt} />
        )}

        {createdAt && (
          <meta property="article:published_time" content={createdAt} />
        )}

        {shareGraphic && (
          <>
            <meta
              property="og:image"
              content={imageBuilder
                .image(shareGraphic)
                .width(1200)
                .height(630)
                .url()}
            />
            <meta
              name="twitter:image"
              content={imageBuilder
                .image(shareGraphic)
                .width(1200)
                .height(630)
                .url()}
            />
          </>
        )}

        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />

        {siteTitle && <meta name="og:site_name" content={siteTitle} />}

        {schema && generateSchema(schema)}
      </Head>

      {/* <CookieBar data={site.cookieConsent} /> */}

      <m.div initial="initial" animate="enter" exit="exit" variants={variants}>
        <Header data={site.header} isTransparent={page.hasTransparentHeader} />
        <main id="content">{children}</main>
        <Footer data={site.footer} />
      </m.div>
    </>
  )
}

export default Layout

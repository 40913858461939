import React from 'react'
import { uuid } from '@sanity/uuid'

const BlockAngleDecorator = (props) => {
  const { configuration, children, uniqueId } = props

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .block-angle-decorator-${uniqueId} {
             background: ${configuration.background}
            }

            .block-angle-decorator-${uniqueId}:after,
            .block-angle-decorator-${uniqueId}:before {
              height: ${configuration.height};
            }

            .block-angle-decorator-${uniqueId}::before {
              top: 0px;
              transform: skewY(${configuration.topAngle});
              transform-origin: ${
                configuration.reverseTop ? '0px 100%' : '100% 0px'
              };
            }

            .block-angle-decorator-${uniqueId}::after {
              bottom: 0px;
              transform: skewY(${configuration.bottomAngle});
              transform-origin: ${
                configuration.reverseBottom ? '100% 0px' : '0px 100%'
              };
            }`,
        }}
      ></style>
      <div
        className={`block-angle-decorator block-angle-decorator-${uniqueId}`}
      >
        {children}
      </div>
    </>
  )
}

export default BlockAngleDecorator

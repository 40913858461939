import React from 'react'
import { useRouter } from 'next/router'
import CustomLink from '@components/link'

export interface PromoBarProps {
  text: string
  link?: string
  display?: string
}

const PromoBar = React.memo(({ data }: { data: PromoBarProps }) => {
  const { text, link, display } = data
  const router = useRouter()

  // bail if no display or text
  if (!display || !display.trim() || !text) return null

  // bail if display set to homepage and we're not on the homepage
  if (display === 'home' && router.asPath !== '/') return null

  return (
    <div className="promo-bar">
      <div className="promo-bar--content">
        {link ? (
          //@ts-ignore
          <CustomLink
            className="promo-bar--link"
            link={{ ...{ page: link }, ...{ title: text } }}
          />
        ) : (
          text
        )}
      </div>
    </div>
  )
})

export default PromoBar

import { SiteHeader } from '@lib/api'
import React, { useState, useRef } from 'react'
import HeaderDefault from './header-default'
import HeaderLeftLogo from './header-left-logo'

export interface HeaderProps {
  data: SiteHeader
  isTransparent: boolean
}

const Header = ({ data, isTransparent }: HeaderProps) => {
  switch (data.layout) {
    case 'header-left-logo':
      return <HeaderLeftLogo data={data} isTransparent={isTransparent} />
    default:
      return <HeaderDefault data={data} isTransparent={isTransparent} />
  }
}

export default Header

import React from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'

const SheetOfPaper = ({ data }) => {
  return (
    <section>
      <div className={cx('sheet-of-paper')}>
        <BlockContent
          renderContainerOnSingleChild
          className="sheet-of-paper__content rc"
          blocks={data.content}
          serializers={serializers}
        />
      </div>
    </section>
  )
}

export default SheetOfPaper

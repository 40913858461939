import React from 'react'
import dynamic from 'next/dynamic'
import BlockAngleDecorator from '@components/block-angle-decorator'
import SheetOfPaper from './sheetOfPaper'

const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const Gallery = dynamic(() => import('./gallery'))
const RetailersList = dynamic(() => import('./retailers-list'))
const MapSection = dynamic(() => import('./map-section'))
const PressList = dynamic(() => import('./press-list'))
const PostsGrid = dynamic(() => import('./posts-grid'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const HubspotForm = dynamic(() => import('./hubspot-form'))
const TimelineSection = dynamic(() => import('./timeline-section'))
const AutoColumns = dynamic(() => import('./auto-columns'))
const Squares = dynamic(() => import('./squares'))
const Quote = dynamic(() => import('./quote'))
const SideBySideImageWithContent = dynamic(
  () => import('./side-by-side-image-with-content')
)
const Paragraph = dynamic(() => import('./paragraph'))
const BrandProfile = dynamic(() => import('./brand-profile'))
const BrandCta = dynamic(() => import('./brand-cta'))
const ImageGrid = dynamic(() => import('./image-grid'))
const ProductHero = dynamic(() => import('./shop/product-hero'))
const Collection = dynamic(() => import('./shop/collection'))
const CollectionPreview = dynamic(() => import('./shop/collection-preview'))

interface ModuleProps {
  uniqueId: any
  module: any
  product?: any
  activeVariantId?: any
  onVariantChange?: any
  collectionProducts?: any[]
  featuredProducts?: any[]
  posts?: any[]
}

export const Module = ({
  uniqueId,
  module,
  product,
  activeVariantId,
  onVariantChange,
  collectionProducts,
  featuredProducts,
  posts,
}: ModuleProps) => {
  const type = module._type
  let moduleBlock = null
  switch (type) {
    case 'grid':
      moduleBlock = <Grid data={module} />
      break
    case 'hero':
      moduleBlock = <Hero data={module} />
      break
    case 'marquee':
      moduleBlock = <Marquee data={module} />
      break
    case 'dividerPhoto':
      moduleBlock = <DividerPhoto data={module} />
      break
    case 'hubspotForm':
      moduleBlock = <HubspotForm data={module} />
      break
    case 'productHero':
      moduleBlock = (
        <ProductHero
          product={product}
          activeVariantId={activeVariantId}
          onVariantChange={onVariantChange}
          showSummary={false}
        />
      )
      break
    case 'collectionGrid':
      moduleBlock = (
        <Collection
          data={module}
          products={collectionProducts}
          featuredProducts={featuredProducts}
        />
      )
      break
    case 'collectionPreview':
      moduleBlock = <CollectionPreview data={module} />
      break
    case 'postsGrid':
      moduleBlock = <PostsGrid data={module} posts={posts} />
      break
    case 'sideBySideImageWithContent':
      moduleBlock = <SideBySideImageWithContent data={module} />
      break
    case 'mapSection':
      moduleBlock = <MapSection data={module} />
      break
    case 'timelineSection':
      moduleBlock = <TimelineSection data={module} />
      break
    case 'retailersList':
      moduleBlock = <RetailersList data={module} />
      break
    case 'pressList':
      moduleBlock = <PressList data={module} />
      break
    case 'autoColumns':
      moduleBlock = <AutoColumns data={module} />
      break
    case 'imageGrid':
      moduleBlock = <ImageGrid data={module} />
      break
    case 'squares':
      moduleBlock = <Squares data={module} />
      break
    case 'brandProfile':
      moduleBlock = <BrandProfile data={module} />
      break
    case 'brandCta':
      moduleBlock = <BrandCta data={module} />
      break
    case 'quote':
      moduleBlock = <Quote data={module} />
      break
    case 'paragraph':
      moduleBlock = <Paragraph data={module} />
      break
    case 'sheetOfPaper':
      moduleBlock = <SheetOfPaper data={module} />
      break
    case 'gallery':
      moduleBlock = <Gallery data={module} />
      break
    default:
      moduleBlock = null
  }
  return module.shouldDecorateWithAngles ? (
    <BlockAngleDecorator
      configuration={module.blockAngleDecorator}
      uniqueId={uniqueId}
    >
      {moduleBlock}
    </BlockAngleDecorator>
  ) : (
    moduleBlock
  )
}
